import {
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';

export function CardHistory( props ) {
  return (
      <Flex 
        maxW={{base:160,md:300}}
        maxH={{base:400,md:500}}
        w={'full'}
        h={{base:350,md:400}}
        overflow={'hidden'}
        direction={'column'}
        >

        <Image 
          alignSelf={'center'}
          boxSize={{base:16,md:24}}
          src= {props.icon}
          mt={{base:1,md:5}} 
          mb={{base:5,md:12}}
          alt={props.alt}
          title={props.title}/>
        <Heading as="h3" fontSize={{base:'lg', md:'2xl'}}>{props.year}</Heading>
        <Text
          fontSize={{base:'sm' , md:'xl'}} >
        {props.text}
        </Text>
      </Flex>
  )
}