import { 
  Center, 
  Heading, 
  SimpleGrid, 
  Box,
  Container,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import Lang from '../Lang';
import { CardHistory } from "./cardHistory";

export function WhoAreWe () {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
  <Box 
    bgColor={useColorModeValue('gray.100', 'gray.700')} 
    id="Who are we?"
    py={6}>
    <Container maxW={'6xl'} >
      <Center>
        <Heading mt={16} mb={8}><Lang text="Who are we?"/></Heading>
      </Center>

      <SimpleGrid columns={2} spacing={10} minH="70vh">
        <Center>
          <CardHistory 
            year={2019}
            icon={colorMode === 'light' ? 'img/historia_03.svg' : 'img/historia_03_white.svg'}
            text={Lang({text:"A precious relationship is born between AppOX and the administration of the health system of the province of Quebec (+10 projects)."})}
            alt="Relationship between AppOX and the administration of the health system of the province of Quebec"
            title="Relationship between AppOX and the administration of the health system of the province of Quebec"
            />
        </Center>
        <Center>
          <CardHistory 
            year={2020}
            icon={colorMode === 'light' ? 'img/historia_04.svg' : 'img/historia_04_white.svg'}
            text={Lang({text:"AppOX, in partnership with Baseline, was classified at the top category as official suppliers of AI for the government of Canada."})}
            alt="Official suppliers of AI for the government of Canada"
            title="Official suppliers of AI for the government of Canada"
          />
        </Center>
        <Center>
          <CardHistory 
            year={2022}
            icon={colorMode === 'light' ? 'img/historia_05.svg' : 'img/historia_05_white.svg'}
            text={Lang({text:"AppOX launches the business model of co-creation of low-cost solutions with shared intellectual property and commercialization."})}
            alt="Business model of co-creation of low-cost solutions"
            title="Business model of co-creation of low-cost solutions"
          />
        </Center>
        <Center>
          <CardHistory 
            year={2023}
            icon={colorMode === 'light' ? 'img/historia_06.svg' : 'img/historia_06_white.svg'}
            text={Lang({text:"AppOX launches its social impact initiatives in developing countries: AI-Teens, AI-Pros and AI-Social Small Enterprises (SSE)."})}
            alt="Social impact initiatives in developing countries"
            title="Social impact initiatives in developing countries"
          />
        </Center>
      </SimpleGrid>
    </Container>
    </Box>
  );
}