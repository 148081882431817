import { 
  Box,
  Text,
  Center,
  Heading,
  SimpleGrid,
  useColorModeValue,
  Card,
  Image,
  Stack,
  CardBody,
  UnorderedList,
  ListItem,
  Flex,
} from "@chakra-ui/react";
import Lang from '../Lang';

export function AppOXExperiences(){

  return (
    <Box 
      bg={useColorModeValue('white', 'gray.800')} 
      id="AppOX Experiences">
      <Center>
        <Heading my={14} as="h2">
          <Lang text="AppOX Experiences" />
        </Heading>
      </Center>
      <Center>
        <SimpleGrid 
          columns={{base:1, md:2}} 
          mx={{base:0,md:10}} 
          mb={{base:10,md:20}} 
          w={{base:'100%',md:'900%'}}
          maxW={'6xl'}
          aling={'center'}>

          <ProjectCard 
            title="Flood Early Warning Systems" 
            img="img/kelly-sikkema-_whs7FPfkwQ-unsplash_1024.jpg" 
            srcSet="img/kelly-sikkema-_whs7FPfkwQ-unsplash_1024.webp" 
            alt="Flood early warning systems"
            titleIMG="Flood early warning systems - Thanks to <a href='https://unsplash.com/es/@kellysikkema?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Kelly Sikkema</a> at <a href='https://unsplash.com/es/fotos/_whs7FPfkwQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Unsplash</a> "/>

          <ProjectCard 
            title="Optimization of Processes in Health Systems" 
            img="img/national-cancer-institute-NFvdKIhxYlU-unsplash_1024.jpg" 
            srcSet="img/national-cancer-institute-NFvdKIhxYlU-unsplash_1024.webp" 
            alt="Optimization of processes in health systems"
            titleIMG="Optimization of processes in health systems - Thanks to <a href='https://unsplash.com/es/@nci?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>National Cancer Institute</a> at <a href='https://unsplash.com/es/fotos/NFvdKIhxYlU?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Unsplash</a>" />

          <ProjectCard 
            title="Privacy Protection" 
            img="img/kevin-ku-w7ZyuGYNpRQ-unsplash_1024.jpg"
            srcSet="img/kevin-ku-w7ZyuGYNpRQ-unsplash_1024.webp"
            alt="Privacy protection" 
            titleIMG="Privacy protection - Thanks to <a href='https://unsplash.com/es/@ikukevk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Kevin Ku</a> at <a href='https://unsplash.com/es/fotos/w7ZyuGYNpRQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Unsplash</a> "/>

          <ProjectCard 
            title="Civil Infrastructure Inspection" 
            img="img/wes-hicks-GlIoShgt2PQ-unsplash_1024.jpg"
            srcSet="img/wes-hicks-GlIoShgt2PQ-unsplash_1024.webp"
            alt="Civil Infrastructure Inspection" 
            titleIMG="Civil Infrastructure Inspection - Thanks to <a href='https://unsplash.com/es/@sickhews?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Wes Hicks</a> at <a href='https://unsplash.com/es/fotos/GlIoShgt2PQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'>Unsplash</a>"/>

        </SimpleGrid>
      </Center>
    </Box>
  );
}
  
function ProjectCard (props){
  return (
  <Card
    direction={{ base: 'column', sm: 'row' }}
    overflow='hidden'
    variant='unstyled' 
    m={5}
    bg={useColorModeValue('gray.50', 'gray.700')}>

    <Image
      display={{base:'flex' , md:'none' , lg:'flex'}}
      objectFit='cover'
      maxW={{ base: '100%', sm: '200px' }}
      src={props.img}
      srcSet={props.srcSet}
      alt={props.alt}
      title={props.titleIMG}
    />

    <Stack>
      <CardBody 
        p={5} 
        mt={5}
        fontSize={{base:"md" , md:"md"}}>
        <Heading 
          size='md' 
          ty={2} 
          mb={3}
          as="h3">
          <Lang text={props.title} />
        </Heading>

        {props.title === "Flood Early Warning Systems" ? <FloodAlerts /> : <></>}
        {props.title === "Optimization of Processes in Health Systems" ? <ProcessOpti /> : <></>}
        {props.title === "Privacy Protection" ? <Privacy /> : <></>}
        {props.title === "Civil Infrastructure Inspection" ? <CivilInfra /> : <></>}
      </CardBody>

    </Stack>
  </Card>
  );
}

function FloodAlerts(){
  return (  
    <Flex justifyContent={"center"}
      flexDirection={'column'}
      h={'90%'}>
      <UnorderedList spacing={5}>
        <ListItem><Lang text="AI to predict floods in La Mojana, Colombia" /></ListItem>
        <ListItem><Lang text="AI for early warning of avalanches in Salgar, Colombia" /></ListItem>
        <ListItem><Lang text="AI to prevent natural disasters in real time in Mocoa, Colombia" /></ListItem>
      </UnorderedList>
    </Flex>
  );
}

function ProcessOpti (){
  return(
  <>
    <Text><Lang text="In Quebec:" /></Text>
    <UnorderedList>
      <ListItem><Lang text="AI to optimize medical billing controls." /></ListItem>
      <ListItem><Lang text="AI for eligibility control in the health system." /></ListItem>
      <ListItem><Lang text="AI to evaluate COVID probability with lung x-rays." /></ListItem>
      <ListItem><Lang text="AI to optimize the processing of medication requests." /></ListItem>
      <ListItem><Lang text="AI for the control of admissibility to the public drug system." /></ListItem>
      <ListItem><Lang text="AI to forecast the medication consumption." /></ListItem>
      <ListItem><Lang text="AI to summarize thousands of beneficiary comments." /></ListItem>
    </UnorderedList>
  </>
  );
}

function Privacy () {
  return(
    <Flex justifyContent={"center"}
      flexDirection={'column'}
      h={{base:"", md:300}}>
      <UnorderedList spacing={5}>
        <ListItem><Lang text="AI to detect inappropriate access to medical records." /></ListItem>
        <ListItem><Lang text="AI for the generation of synthetic data of relational and transactional tables." /></ListItem>
        <ListItem><Lang text="AI to hide private information in text messages." /></ListItem>
      </UnorderedList>
    </Flex>
    );
}

function CivilInfra (){
  return (
    <Flex justifyContent={"center"}
      flexDirection={'column'}
      h={'90%'}>
      <Text>
        <Lang text="AI to identify structural failures in concrete bridges with infrared drone images" />
      </Text>
    </Flex>
  );
}