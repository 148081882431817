import {
  Heading,
  Text,
  Flex,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import Lang from '../Lang';

export function DetailsService (props) {
  const ExpertsInHouse = Lang({text:"We facilitate the creation of your AI team with a standard process of the maturity BI/AI plan, roles and responsibilities, tools, execution and project time, documentation, data life cycle, validation strategies and staff training plans. This service is ideal for industries who wish to develop teams within their facilities due to the sensitive nature of data or their vision about developing in-house solutions."});
  const ReadyToUse = Lang({text:"We have a highly qualified team in the development of AI solutions from our own workplace. This service is ideal for organizations where the data sensitivity is not highly complex."});
  const Reidentification = Lang({text:"We offer an AI solution to measure reidentification risks and prioritize privacy in your databases (see RGPD or Quebec's law 25 about the Protection of Personal Information). This solution can be deployed in house or in cloud technologies."});

  return(
    <Flex 
      h={{base:'' ,md:'100%'}}
      flexDirection={'column'}
      pt={6}>

      <Heading 
        alignSelf={'center'}
        mb={{base:4 , md:8}}
        size={'xl'}>
        <Lang text={props.title} />
      </Heading>

      { props.title == "What do we do?" ? <ListWhatDoWeDo /> : <></>}
      { props.title == "AI Experts In-house" ? <Texts description={ExpertsInHouse} /> : <></>}
      { props.title == "AI Solutions Ready-to-use" ? <Texts description={ReadyToUse} /> : <></>}
      { props.title == "Reidentification Analysis" ? <Texts description={Reidentification} /> : <></>}
      { props.title == "Synthetic Data Generation" ? <Synthetic /> : <></>}

    </Flex>
  );
}

function ListWhatDoWeDo (){
  return (
    <UnorderedList             
      spacing={2}
      fontSize={{base:'md',md:'xl'}}>
      <ListItem><Lang text="We develop your AI projects by training your teams or with ready-to-use innovations." />  </ListItem>
      <ListItem><Lang text="We estimate your data quality." /></ListItem>
      <ListItem><Lang text="We identify your AI needs with privacy life and ethics by design." /></ListItem>
      <ListItem><Lang text="We propose smart solutions at high ROI." /></ListItem>
      <ListItem><Lang text="We work with cloud or on premises solutions." /></ListItem>
      <ListItem><Lang text="We evaluate your infrastructure and digital plan." /></ListItem>
      <ListItem><Lang text="We co-create an operational architecture." /></ListItem>
      <ListItem><Lang text="We offer AI technologies with an interpretation layer (no black box solutions)." /> </ListItem>
    </UnorderedList>
  );
}

function Texts( {description} ){
  return(
    <Text 
      fontSize={{base:'md',md:'xl'}}>
      {description}
    </Text>
  );
}

function Synthetic(){
  return(
  <>
    <Text 
      fontSize={{base:'md',md:'xl'}}>
      <Lang text="We provide an AI solution to generate synthetic data. We use it to improve AI model performance with data augmentation and for sharing information without any risk." />
    </Text>
    <Text 
      fontSize={{base:'md',md:'xl'}}>
      <Lang text="We deliver this solution for relational and transactional databases, testing the trade-off between information loss, confidentiality and utility." />
    </Text>
  </>
  );
}