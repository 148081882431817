import {
  Box,
  VStack,
  Grid,
  Image,
  Heading,
} from '@chakra-ui/react';

import Lang from '../Lang'

export function Header () {
  return (
    <Box textAlign="center" fontSize="xl" width="100%" >
      <Grid minH={{base:"80vh",md:"90vh"}}  mt={-10} width="100%" 
        backgroundImage="url('img/appox_banner-principal_02.jpg')" 
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        >

        <VStack spacing={8} >
          <Image 
            h="5vmin" 
            mt={20} 
            src='img/appox_header.svg' 
            display={{ base:'none' , md: 'flex' }} 
            title='AppOX'
            alt='AppOX logo'/>
        </VStack>

        <VStack spacing={8}>
          <Heading 
            as={'h1'}
            color={'gray.50'}
            size={'xl'}
            p={10}
            maxW={'4xl'}>
            <Lang text="AI Specialists and Guardians of Data Privacy" />
          </Heading>
        </VStack>

      </Grid>
    </Box>
  );
}
