import {
  Heading,
  Flex,
  Image
} from '@chakra-ui/react';
import Lang from '../Lang';

export function CardService( props ) {
  return (
      <Flex 
        maxW={{base:150,md:250}}
        maxH={{base:230,md:500}}
        w={'full'}
        h={{base:230,md:300}}
        p={3}
        m={2}
        bg={ props.tab === props.service ? '#112653' : 'gray.50'}
        boxShadow={'2xl'}
        rounded={20}
        overflow={'hidden'}
        style={{cursor:'pointer'}}
        direction={'column'}
        alignSelf={'center'}
        >

        <Image 
          alignSelf={'center'}
          boxSize={{base:20,md:28}}
          src= { props.tab === props.service ? props.icon : 'img/servicios_00.svg'}
          mt={{base:3,md:8}}
          title={props.title}
          alt={props.alt}
        />

        <Heading 
          fontSize={{base:'md' , md:'xl'}} 
          fontWeight={500} 
          fontFamily={'body'} 
          my={{base:3,md:8}}
          textAlign={'center'}
          color={ props.tab === props.service ? 'gray.50' : 'gray.900'}>
          {props.asset} <br/> <Lang text={props.name} />
        </Heading>

      </Flex>
  )
}