import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  UnorderedList,
} from '@chakra-ui/react';
import Lang from '../Lang';
import { FaCheckCircle } from "react-icons/fa";

export  function WhyAppOX() {
  return (
    <>
    <Container 
      maxW={'6xl'} 
      pt={{ base:16 , md:20}} 
      pb={{ base:5 , md:20}} 
      id="Why AppOX" >

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} >

        <Image
          rounded={'xl'}
          h={'100%'}
          alt={'We are AppOX'}
          title={'AppOX: guardians of data privacy'}
          src={'img/appox_nosotros_movil_01.jpg'}
          srcSet={'img/appox_nosotros_movil_01.webp'}
          objectFit={'cover'}
          display={{base:'none',md:'flex'}}
        />

        <Flex flexDirection="column" spacing={2}  justifyContent={'center'}>
          <Heading 
            display='flex'  
            justifyContent='center' 
            mb={12}
            mt={-6}
            size={'xl'} >
            <Lang text='Why AppOX?' />
          </Heading>

          <List 
            spacing={5}
            fontSize={{base:'md',md:'xl'}} 
            mb={5}>
            <ListItem>
              <ListIcon as={FaCheckCircle} color='teal.400' />
              <Lang text="We unleash your data to the smart use of AI solutions." />
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color='teal.400' />
              <Lang text="We guide organizations towards the digital transformation with cloud or in-house AI solutions." />
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color='teal.400' />
              <Lang text="We help you with AI projects at high ROI." />
            </ListItem>
            <ListItem>
              <ListIcon as={FaCheckCircle} color='teal.400' />
              <Lang text="We develop innovate solutions, with a team led by Ph.D. level data scientist, in areas such as:" />
            </ListItem>

              <UnorderedList 
                spacing={3}
                ml={16}>
                <ListItem>
                  <Lang text="Anomaly detection." />
                </ListItem>
                <ListItem>
                  <Lang text="Process optimization." />
                </ListItem>
                <ListItem>
                  <Lang text="Time series prediction." />
                </ListItem>
                <ListItem>
                  <Lang text="Text analysis." />
                </ListItem>
                <ListItem>
                  <Lang text="Image processing." />
                </ListItem>
              </UnorderedList>

          </List>

          <Text 
            align={'center'}
            as={'strong'}
            fontSize={{base:'lg', md:'2xl'}}
            my={5}
            p={5}
            bg={'blue.900'}
            color={'gray.100'}
            borderRadius={10}
            >
            <Lang text="Our services allow you to optimize your processes and make smart decisions."/>
          </Text>
        </Flex>

      </SimpleGrid>

    </Container>
    <Image
      alt={'feature image'}
      src={'img/appox_nosotros_movil_01.jpg'}
      srcSet={'img/appox_nosotros_movil_01.webp'}
      objectFit={'cover'}
      display={{base:'flex',md:'none'}}
    />
</>  
  )
}