import React from 'react';
import {
  Box,
  ChakraProvider,
  theme,

  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
  Link,
} from '@chakra-ui/react';

import { Contact } from './components/ContactForm';
import { Header } from './components/header';
import { Navbar } from './components/navbar';
import { WhyAppOX } from './components/whyAppOX';
import { WhatDoWeDo } from './components/whatDoWeDo';
import { AppOXExperiences } from './components/appOXExperiences';
import { WhoAreWe } from './components/whoAreWe';
import { Footer } from './components/footer';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Contact />
      <Box display={{ base: 'inline', md: 'none' }}><Navbar /></Box>
      <Header />
      <Box display={{ base: 'none', md: 'inline' }}><Navbar /></Box>
      <PopUp />
      <WhyAppOX />
      <WhatDoWeDo />
      <AppOXExperiences />
      <WhoAreWe />
      <Footer />
    </ChakraProvider>
  );
}

function PopUp() {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  return (
    <>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={"4xl"}
      >
        <ModalOverlay
          bg='none'
          backdropFilter='auto'
          backdropInvert='10%'
          backdropBlur='2px'
        />
        <ModalContent
          bg="#000"
        >
          <ModalHeader>&nbsp;</ModalHeader>
          <ModalCloseButton color={"white"} />
          <ModalBody>
            <Link href='https://appox.ai/anonymox/c3223b90c7dae719.pdf' target='_blank'>
              <Image src='img/01_Anonymox_Popup.jpg' />
            </Link>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}

export default App;