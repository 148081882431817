import {
  Box,
  Flex,
  HStack,
  Text,
  IconButton,
  Image,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  useColorModeValue,
  Stack,
  Link,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { AiOutlineGlobal } from 'react-icons/ai';

import Lang from '../Lang';

const Links = [ "Why AppOX" , "What do we do?" , "AppOX Experiences" , "Who are we?" ];

const NavLink = (props) => {
  const { children } = props;

  return (
    <Box
      as="a"
      px={2}
      py={2}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.100', 'gray.700'),
      }}
      href={'#'+children}>
      <Text fontSize="xl"><Lang text={children} /></Text>
    </Box>
  )
}

export function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
      <Box 
        bg={{ base:useColorModeValue('black', 'black') , md:useColorModeValue('gray.200', 'gray.900') }} 
        color={{ base:useColorModeValue('gray.50', 'gray.50') , md:useColorModeValue('gray.800', 'gray.100') }} 
        px={4}
        >

        <Flex 
          h={{md:20}} 
          py={5}
          alignItems={'center'} 
          justifyContent={{ base:'space-between' , md:'center' }}
          >
          
          <HStack spacing={10} alignItems={ 'center'} display={{ base: 'none', md: 'flex' }} >
            <HStack as={'nav'} spacing={4} >
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </HStack>
          </HStack>
          
          <Image 
            h="8vmin"  
            src='img/appox_header.svg' 
            display={{ base:'flex' , md:'none' }}
            title='AppOX'
            alt='AppOX logo' />

          <Flex alignItems={'center'}>
            <ColorModeSwitcher 
              fontSize={16}
              color={{base:useColorModeValue('gray.100', 'gray.100'),md:useColorModeValue('gray.700', 'gray.100')}} 
              justifySelf="flex-end" 
              _hover={{
                textDecoration: 'none',
                color: {base:useColorModeValue('gray.300', 'gray.300'),md:useColorModeValue('gray.900', 'gray.100')} ,
                bg: {base:useColorModeValue('gray.900', 'gray.900'),md:useColorModeValue('gray.100', 'gray.700')} ,
                borderRadius:'md'
              }}/>
          </Flex>

          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon boxSize={5}/> : <HamburgerIcon  boxSize={5}/>}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
            bg={''}
            color={useColorModeValue('gray.50', 'gray.100')}
          />

        </Flex>

        {isOpen ? (
          <Box
            display={{ md: 'none' }} 
            h={255} >
            <Stack as={'nav'} spacing={2}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
  )
}

const ChangeLang = () => {
  const linkColor = useColorModeValue('gray.400', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} >
        <Flex alignItems={'center'} mt={1} mr={-1} >
          <Popover trigger={'click'} placement={'bottom-start'}>

            <PopoverTrigger>
              <Box 
                p={2}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                  bg: {base:useColorModeValue('gray.900', 'gray.900'),md:useColorModeValue('gray.100', 'gray.700')} ,
                  borderRadius:'md'
                }}>
                <Link href={'#'} >
                  <Icon 
                    as={AiOutlineGlobal}
                    color= {{base:useColorModeValue('gray.200', 'gray.200'),md:useColorModeValue('gray.700', 'gray.100')}} 
                    boxSize={5}/>
                </Link>
              </Box>
            </PopoverTrigger>

            <PopoverContent 
              border={0}
              boxShadow={'xl'}
              bg={popoverContentBgColor}
              color={useColorModeValue('gray.800', 'gray.300')}
              p={1}
              rounded={'xl'}
              w={'100px'}>
              <Stack >
                <OptionsLang lang={'EN'}/>
                <OptionsLang lang={'FR'}/>
                <OptionsLang lang={'ES'}/>
              </Stack>
            </PopoverContent>

          </Popover>
        </Flex>
    </Stack>
  );
}

const OptionsLang = ({lang}) => {
  return (
    <Box onClick={ () => {
      localStorage.setItem("lang", lang.toLowerCase() ); 
      window.location.reload(false); 
    } }
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('blue.50', 'gray.900') }}>
      <Link
        href={'#'} >
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'blue.400' }}
              fontWeight={500}>
              {lang}
            </Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'blue.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    </Box>
  );
}