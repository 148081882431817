import {
  SimpleGrid,
  Box,
  Container,
  useColorModeValue,
} from '@chakra-ui/react';

import { useState, useTransition } from 'react';
import Lang from '../Lang';
import { CardService } from './cardService';
import { DetailsService } from './detailsService'

export function WhatDoWeDo () {
  const [ isPending, startTransition ] = useTransition();
  const [ tab, setTab ] = useState('main');

  function selectTab( nextTab ) {
    startTransition(() => {
      setTab(nextTab);      
    });
  }

  const title = Lang({text:"What do we do?"});

  const AITeam_asset = Lang({text:"Service 1"});
  const AITeam_name = "AI Experts In-house";

  const AISolition_asset = Lang({text:"Service 2"});
  const AISolition_name = "AI Solutions Ready-to-use";

  const Identification_asset = Lang({text:"Product 1"});
  const Identification_name = "Reidentification Analysis";

  const SyntheticData_asset = Lang({text:"Product 2"});
  const SyntheticData_name = "Synthetic Data Generation";

  return (
    <Box 
      bgColor={useColorModeValue('gray.200', 'gray.700')} 
      id="What do we do?">
      <Container maxW={'6xl'} py={{base:6 , md:20}}>
        <SimpleGrid 
          columns={{ base:1 , md:1 , lg:2  }}
          spacing={10}
          minH="70vh" >

          <Box onClick={() => selectTab('main')} >
            {tab === 'main' && <DetailsService title={title} />}
            {tab === 'AI-Team' && <DetailsService title={AITeam_name} />}
            {tab === 'AI-solition' && <DetailsService title={AISolition_name} />}
            {tab === 'Identification' && <DetailsService title={Identification_name} />}
            {tab === 'Synthetic-data' && <DetailsService title={SyntheticData_name} />}
          </Box>

          <SimpleGrid columns={{ base: 2, md: 4 ,  lg:2  }}>

            <Box 
              onMouseOver={() => selectTab('AI-Team')} 
              display={'flex'} 
              justifyContent={'center'} 
              w={'full'}>
              <CardService 
                tab={tab}
                service='AI-Team'
                icon="img/servicios_01.svg"
                asset={AITeam_asset}
                name={AITeam_name} 
                title={"Service: AI Experts in-house"}
                alt={" Service: AI Experts in-house"} />
            </Box>

            <Box 
              onMouseOver={() => selectTab('AI-solition')} 
              display={'flex'} 
              justifyContent={'center'} 
              w={'full'}>
              <CardService 
                tab={tab}
                service='AI-solition'
                icon="img/servicios_02.svg"
                asset={AISolition_asset}
                name={AISolition_name} 
                title={"Service: AI Solutions Ready-to-use"}
                alt={" Service: AI Solutions Ready-to-use"} />
            </Box>

            <Box 
              onMouseOver={() => selectTab('Identification')} 
              display={'flex'} 
              justifyContent={'center'} 
              w={'full'}>
              <CardService 
                tab={tab}
                service='Identification'
                icon="img/servicios_04.svg"
                asset={Identification_asset}
                name={Identification_name} 
                title={"Product: Reidentification Analysis"}
                alt={" Product: Reidentification Analysis"} />
            </Box>

            <Box 
              onMouseOver={() => selectTab('Synthetic-data')} 
              display={'flex'} 
              justifyContent={'center'} 
              w={'full'}>
              <CardService 
                tab={tab}
                service='Synthetic-data'
                icon="img/servicios_03.svg"
                asset={SyntheticData_asset}
                name={SyntheticData_name} 
                title={"Product: Synthetic Data Generation"}
                alt={" Product: Synthetic Data Generation"} />
            </Box>

          </SimpleGrid>

        </SimpleGrid>
      </Container>
    </Box>
  );
}