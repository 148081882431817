import es from './lang/es';
import fr from './lang/fr';

const Idiom = () => {
  var Lang = navigator.language.substring(0, 2);
  return Lang;
}

const Lang = ({text}) => {
  const localLang = localStorage.getItem("lang");
  var lang = '';

  if(!localLang){
    lang = Idiom();
  }else{
    lang = localLang;
  }

  if (lang === "es") var dic_lang = es;
  if (lang === "fr") var dic_lang = fr;
  
  if (typeof dic_lang === 'undefined') {
    return text;
  } else {
    if (dic_lang.hasOwnProperty(text)) {
      return dic_lang[text];
    } else {
      return text;
    }
  }
}

export default Lang;