import {
  Box,
  Link,
  VStack,
  Grid,
  Image,
  Text,
  Flex,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaExternalLinkAlt } from "react-icons/fa";
import Lang from '../Lang';

export function Footer() {
  return (
    <Box 
      fontSize={{base:"sm",md:"md"}}
      id={"Contact"}
      bg={useColorModeValue('gray.50', 'gray.900')}>

      <Grid minH={{ base:'50vh', md:'50vh' }}
        backgroundImage="url('img/appox_contacto_movil_01.jpg')"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover" >

        <VStack>
          <Image 
            h={{base:50,md:100}}
            my={{base:50,md:50}}
            src='img/appox_footer.svg' />

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            mb={16}
            display={{ base:'none', md:'flex' }} >

            <Link href='https://medium.com/@AppOX' isExternal >
              <Flex 
                alignItems="center" 
                w={300} 
                justifyContent={'center'}>
                <Image src='img/medium.png' h={10} />
                <Text color="white" ml={2} mr={6}> <Lang text="Follow us!"/> &nbsp; <Icon as={FaExternalLinkAlt} boxSize={4} color="teal.400" /> </Text>
              </Flex>
            </Link>

            <Link href='https://www.linkedin.com/company/apox-ai' isExternal >
              <Flex 
                alignItems="center" 
                w={300} 
                justifyContent={'center'}>
                <Image src='img/linkedin.svg' h={10} color="teal.400" />
                <Text color="white" ml={2} mr={6}> <Lang text="Follow us!"/> &nbsp; <Icon as={FaExternalLinkAlt} boxSize={4} color="teal.400" /> </Text>
              </Flex>
            </Link>

          </SimpleGrid>

          <SimpleGrid 
            columns={{ base: 1, md: 3 }} 
            spacing={6} 
            mb={20}>

            <Link href='https://medium.com/@AppOX' isExternal  display={{ base: 'flex', md: 'none' }}>
              <Flex alignItems="center" >
                <Image src='img/medium.png' h={7} />
                <Text color="white" mx={2}><Lang text="Follow us!"/> &nbsp; <Icon as={FaExternalLinkAlt} boxSize={3} color="teal.400" /> </Text>
              </Flex>
            </Link>

            <Link href='https://www.linkedin.com/company/apox-ai' isExternal display={{ base: 'flex', md: 'none' }}>
              <Flex alignItems="center" >
                <Image src='img/linkedin.svg' h={7} color="teal.400" />
                <Text color="white" mx={2}><Lang text="Follow us!"/> &nbsp; <Icon as={FaExternalLinkAlt} boxSize={3} color="teal.400" /> </Text>
              </Flex>
            </Link>

            <Link href='mailto:info@appox.ai' style={{'decorator':'none'}}>
              <Flex w={250} alignItems='center' justifyContent={'start'}>
                <Image src='img/mail_01.svg' h={{base:7, md:10}} />
                <Text color="white" mx={2}>info@appox.ai</Text> 
              </Flex>
            </Link>

            <Link href='tel:+18552776946' style={{'decorator':'none'}}>
              <Flex w={250} alignItems="center" justifyContent={{ base:'start', md:'center' }}>
                <Image src='img/movil_01.svg' h={{base:7, md:10}} />
                <Text color="white" mx={2}>+1 855-APPOX-GO</Text>
              </Flex>
            </Link>

            <Link href='https://g.page/r/CRRFjMxyciDbEBM/review' isExternal style={{'decorator':'none'}}>
              <Flex w={250} alignItems="center" justifyContent={{ base: 'start', md: 'end' }}>
                <Image src='img/ubicacion_01.svg' h={{base:7, md:10}} />
                <Text color="white" ml={2}> <Lang text='Quebec' />, QC, <Lang text='Canada' /> </Text>
              </Flex>
            </Link>

          </SimpleGrid>
        </VStack>
      </Grid>

      <Box h={6}>
        <Text
          align={'center'}
          my={2}>
          <Lang text="Powered by"/> App<b>OX</b> 2024
        </Text>
      </Box>
    </Box>
  );
}