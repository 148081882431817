import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Text,
  Center,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { Formik , Field} from 'formik';
import { EmailIcon } from '@chakra-ui/icons'
import Lang from '../Lang';

export function Contact (){
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()

  const SeccessNotificacion = Lang({text:'The message has been sent successfully!'})

  return (
  <>
    <Box 
      position={'fixed'} 
      zIndex={1}
      bottom={{base:8,md:9}}
      right={{base:'3%',md:'2%'}}
      display={'inline'}
      boxShadow='xl'
      borderRadius={40}>

      <Button 
        onClick={onOpen}
        leftIcon={ <EmailIcon /> }
        borderRadius={40}
        bgGradient='radial(#4AEADE, teal.300)'
        px={{base:5,md:10}}
        py={{base:7,md:10}}
        color={'gray.900'}
        size={'lg'}>
        <Lang text="Let's talk"/>
      </Button>
    </Box>

    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size={'lg'} >
      <ModalOverlay  />

      <ModalContent 
        bg={"rgba(0,0,0,0.8)"}
        color={'gray.50'}
        >
        <ModalHeader>
          <Center>
            <Heading mt={5}>
              <Lang text="Let's talk"/>
            </Heading>
          </Center> 
        </ModalHeader>

        <ModalCloseButton />

        <Flex 
          align="center" 
          justify="center" 
          h={500} 
          w='100%' 
          display={'block'}>

          <Formik
            initialValues={{
              name:"",
              mail: "",
              message: "",
              id: 1
            }}
            validate={values => {
              const errors = {};

              if (!values.name){
                errors.name = Lang({text:"Required"}) ;
              }else if(values.name.length < 3){
                errors.name = Lang({text:"A valid name is required"});
              }
              if (!values.mail) {
                errors.mail = Lang({text:"Required"});
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)
              ){
                errors.mail = Lang({text:"Invalid email address"});
              }

              if (!values.message){
                errors.message = Lang({text:"Required"});
              }else if(values.message.length < 10){
                errors.message = Lang({text:"Use more text"});
              }

              return errors;
            }}
            onSubmit={(values) => {
              const requestOptions = {
                method: 'POST',
                crossDomain: true,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
            };
            fetch('https://appox.ai/api/v01/index.php', requestOptions)
            .then(response => response.json())
            .then(data => {
              console.log({data});
              onClose();
              (() =>
              toast({
                title: SeccessNotificacion,
                position: 'bottom',
                isClosable: true,
              }))();
            });

            }}
            >
            {({ handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <ModalBody>

                <VStack 
                  spacing={4} 
                  align="flex-start" 
                  border='1px' 
                  borderColor='gray.50'
                  borderRadius={20}
                  p={5}>

                  <FormControl>
                    <FormLabel htmlFor="name" >
                      <Center>
                        <Text 
                          color={'teal.300'}
                          fontSize={'xl'}
                          as={'b'}>
                          <Lang text="Name" />
                        </Text>
                      </Center>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="name"
                      name="name"
                      type="text"
                      autofocus="autofocus"
                      variant='flushed'
                    />
                    <Text color={'red'} fontSize={'xs'}>{errors.name && touched.name && errors.name}</Text>
                  </FormControl>

                  <FormControl >
                    <FormLabel htmlFor="mail">
                      <Center>
                        <Text 
                          color={'teal.300'}
                          fontSize={'xl'}
                          as={'b'}>
                          <Lang text="Email address" />
                        </Text>
                      </Center>
                    </FormLabel>
                    <Field
                      as={Input}
                      id="mail"
                      name="mail"
                      type="email"
                      variant='flushed'
                    />
                    <Text color={'red'} fontSize={'xs'}>{errors.mail && touched.mail && errors.mail}</Text>
                  </FormControl>

                  <FormControl >
                    <FormLabel htmlFor="message">
                      <Center>
                        <Text 
                          color={'teal.300'}
                          fontSize={'xl'}
                          as={'b'}>
                          <Lang text="Message" />
                        </Text>
                      </Center>
                    </FormLabel>
                    <Field
                      as={Textarea}
                      id="message"
                      name="message"
                      type="text"
                      variant='flushed'
                      color={'gray.100'}
                      bg={"rgba(0,0,0,0)"}
                      _focus={{
                        color:'gray.100'
                      }}
                      _hover={{
                        color:'gray.100',
                        bg:"rgba(0,0,0,0)"
                      }}
                    />
                    <Text color={'red'} fontSize={'xs'}>{errors.message && touched.message && errors.message}</Text>
                  </FormControl>

                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button 
                  type="submit" 
                  color={'gray.900'}
                  bgGradient='radial(#4AEADE, teal.300)'
                  mr={3} >
                  <Lang text='Send' />
                </Button>
              </ModalFooter>

            </form>
            )}
          </Formik>
        </Flex>
      </ModalContent>
    </Modal>
  </>
  );
}